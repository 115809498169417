import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    pageTitle: "",
  },
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
      document.title = i18next.t(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageTitle } = appSlice.actions;

export default appSlice.reducer;
