import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store";
import reportWebVitals from "./reportWebVitals";
import { AppKitProvider } from "./wallet/AppKitProvider";

import "./index.scss";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
// import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StrictMode>
    <BrowserRouter basename="/">
      <PrimeReactProvider>
        <Provider store={store}>
          <AppKitProvider>
            <App />
          </AppKitProvider>
        </Provider>
      </PrimeReactProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
