import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translation/en.json";
import ko from "./translation/ko.json";
import zh from "./translation/zh.json";
import ja from "./translation/ja.json";
import axios from "../api/axios";

const lang = localStorage.getItem("lang") || "en";

axios.defaults.headers.common["lang"] = lang;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      ko: {
        translation: ko,
      },
      zh: {
        translation: zh,
      },
      ja: {
        translation: ja,
      },
    },
    lng: lang,
    fallbackLng: lang,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
