import { Suspense, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getI18n, I18nextProvider } from "react-i18next";

import Home from "./pages/Home/Home.lazy";
import NotFound from "./pages/NotFound/NotFound.lazy";
import Swap from "./pages/Swap/Swap.lazy";

import "./i18n";
import "./App.scss";

function App() {
  const [i18n] = useState(getI18n());

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      </Suspense>
    </I18nextProvider>
  );
}

export default App;
