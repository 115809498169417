class Setting {
  projectId: string;
  apiUrl: string;

  constructor() {
    this.projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || EMPTY;
    this.apiUrl = process.env.REACT_APP_API_URL || EMPTY;
  }
}

const EMPTY: string = "";

const envConfig: Setting = new Setting();

export { Setting, envConfig };
