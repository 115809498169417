import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    user: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      if (action.payload) state.token = action.payload;
      else {
        state.token = null;
        state.user = null;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setToken } = authSlice.actions;

export default authSlice.reducer;
